<template>
  <div />
</template>
<script setup lang="ts">
/**
 * 要素外クリック検出
 */
const props = defineProps<{
  modelValue: boolean
  element: HTMLElement | undefined
}>()
interface Emits{
  (e: 'update:modelValue', val: boolean): void;
}
const emits = defineEmits<Emits>()
const clickOutside = (e: MouseEvent) => {
  if (e.target instanceof Node && props.element && !props.element.contains(e.target)) {
    emits('update:modelValue', false)
  }
}

onMounted(() => {
  addEventListener('click', clickOutside)
})
onBeforeUnmount(() => {
  removeEventListener('click', clickOutside)
})
</script>
